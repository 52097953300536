<template>
  <div class="select text-lightGrey font-proximaLight">
    <select
      class="font-proximaLight"
      required
      @change="onSelectChange"
      v-model="value"
      :disabled="editable"
    >
      <option class="text-subheaderText" value="" disabled selected>{{ title }}</option>
      <option class="text-subheaderText" v-for="(data) in dataList" :key="data.id" :value="data.id">
        {{ data.name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      // default: this.$t('global_select_options'),
    },
    dataList: {
      type: [String, Array],
      default: function () {
        return ["Option 1", "Option 2"];
      },
    },
  },
  data() {
    return {
      value: "",
    };
  },
  methods: {
    onSelectChange() {
      // console.log(this.value)
      this.$emit("selected", this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
:root {
  --background-gradient: linear-gradient(30deg, #f39c12 30%, #f1c40f);
  --gray: #354e57;
  --darkgray: #231f20;
}

select {
  /* Reset Select */
  appearance: none;
  outline: 0;
  border: 0;
  box-shadow: none;
  /* Personalize */
  flex: 1;
  padding: 0 1em;
  // color: #231f20;
  background-color: #fff;
  background-image: none;
  cursor: pointer;
}
/* Remove IE arrow */
select::-ms-expand {
  display: none;
}
/* Custom Select wrapper */
.select {
  position: relative;
  display: flex;
  //   width: 20em;
  height: 3em;
  border-radius: 0.25em;
  overflow: hidden;
}
/* Arrow */
.select::after {
  content: "\25BC";
  position: absolute;
  top: -4px;
  right: 0;
  padding: 1em;
  background-color: #fff;
  transition: 0.25s all ease;
  pointer-events: none;
}
/* Transition */
.select:hover::after {
  color: #0ac0cb;
}
</style>
