<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="50" cy="50" r="50" fill="#0AC0CB" />
    <path
      d="M60.4167 47.9167H52.0833V39.5833C52.0833 39.0308 51.8638 38.5009 51.4731 38.1102C51.0824 37.7195 50.5525 37.5 50 37.5C49.4475 37.5 48.9176 37.7195 48.5269 38.1102C48.1362 38.5009 47.9167 39.0308 47.9167 39.5833V47.9167H39.5833C39.0308 47.9167 38.5009 48.1362 38.1102 48.5269C37.7195 48.9176 37.5 49.4475 37.5 50C37.5 50.5525 37.7195 51.0824 38.1102 51.4731C38.5009 51.8638 39.0308 52.0833 39.5833 52.0833H47.9167V60.4167C47.9167 60.9692 48.1362 61.4991 48.5269 61.8898C48.9176 62.2805 49.4475 62.5 50 62.5C50.5525 62.5 51.0824 62.2805 51.4731 61.8898C51.8638 61.4991 52.0833 60.9692 52.0833 60.4167V52.0833H60.4167C60.9692 52.0833 61.4991 51.8638 61.8898 51.4731C62.2805 51.0824 62.5 50.5525 62.5 50C62.5 49.4475 62.2805 48.9176 61.8898 48.5269C61.4991 48.1362 60.9692 47.9167 60.4167 47.9167Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
  },
};
</script>