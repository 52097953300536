<template>
  <label class="container font-proximaLight capitalize" :class="[width]">
    <input type="radio" @change="valChange()" :checked="checked" :name="name" />
    <input
      type="text"
      :name="name"
      v-model="textVal"
      :placeholder="placeholder"
      class="w-10 input-text p-1 border border-primary rounded-sm opacity-100 relative"
      @change="valChange()"
    />
    <span class="checkmark"></span>
  </label>
</template>

<script>
export default {
  props: {
    val: String,
    checked: Boolean,
    width: String,
    name: String,
    placeholder: {
      type: String,
      default: "Enter custom frequency",
    },
  },
  data() {
    return {
      textVal: this.val,
    };
  },
  methods: {
    valChange() {
      // console.log(this.textVal);
      this.$emit("inputValText", this.textVal);
    },
  },
};
</script>

<style scoped>
.input-text {
  position: relative !important;
  opacity: 1 !important;
}
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  text-align: left;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
</style>